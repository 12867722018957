.Solution {
    margin: 4rem 4rem;
}

@media (max-width:600px) {
    .Solution {
        margin: 4rem;
    }
}

.Solution.small {
    margin: 0 4rem;
}

.Solution.small h2 {
    font-size: 1.8rem;
    line-height: 3rem;
    margin: 0;
}
