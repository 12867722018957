.ThreeColumnContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 7rem;
}

.ThreeColumnContainer > div {
    flex: 1 1 26%;
    margin: 1rem;
}

.ThreeColumnContainer h2 {
    font-size: 1.5rem;
    font-weight: 600;
}
