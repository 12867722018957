.AboutMe {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    color: white;
    font-weight: 600;
}

#myVideo {
    position: absolute;
    left: 0;
    top: 6rem;
    min-width: 100%;
    min-height: calc(100% - 6rem);
    width: 100%;
    z-index: -1;
    object-fit: cover;
    object-position: left;
}

.AboutMe .aboutMeText {
    margin: 3rem 6rem;
    font-size: 3rem;
    line-height: 4rem;
}

.AboutMe .cheerMe {
    font-family: 'Caveat', cursive;
    position: absolute;
    bottom: 4rem;
    font-size: 2.5rem;
    color: #FFBE98;
    margin-right: 1.5rem;
    text-decoration: none;
}

.AboutMe .title {
    font-size: 4rem;
    line-height: 4rem;
    margin: 0.5rem;
    margin-left: 14rem;
}

.AboutMe .subtitle,
.AboutMe .subtitle2,
.AboutMe .subtitle3 {
    font-size: 2rem;
    line-height: 2rem;
    margin: 0.5rem;
    margin-left: 14rem;
    font-weight: 500;
}

.AboutMe .subtitle2 {
    font-size: 1.2rem;
    line-height: 1.8rem;
}

.AboutMe .subtitle3 {
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin-left: 0;
}

.AboutMe .mt {
    margin-top: 4rem;
}

.AboutMe .arrowup {
    height: 32px;
    width: 32px;
}

.AboutMe .seeMyWork {
    font-size: 2rem;
    cursor: pointer;
    color: #9AD3E9;
}

.AboutMe .leftPanel {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    color: black;
}

.AboutMe .rightPanel {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: black;
}

.AboutMe .ar {
    text-align: right;
}

.AboutMe .italic {
    font-style: italic;
}

@media (max-width:600px) {
    .AboutMe {
        flex-direction: column;
    }

    .AboutMe .leftPanel {
        width: 100%;
    }

    .AboutMe .rightPanel {
        width: 100%;
    }

    .AboutMe .title {
        margin-left: 2rem;
    }

    .AboutMe .subtitle {
        margin-left: 2rem;
    }

    .AboutMe .subtitle2 {
        margin-left: 2rem;
    }
}

.AboutMe .photo {
    height: 25rem;
    width: 25rem;
    background-color: white;
    border-radius: 50%;
}

.AboutMe .photo img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
}
