.TitleBox {
    margin: 4rem 4rem;
    padding-top: 0.1rem;
}

@media (max-width:600px) {
    .TitleBox {
        margin: 4rem;
    }
}
