body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3rem;
  line-height: 2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width:600px) {
    html {
        font-size: 0.5rem;
    }

    body {
        line-height: 2.5rem;
    }

    ul {
        padding-left: 2rem;
    }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
