.TopNav {
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #9AD3E9;
    width: 100%;
    z-index: 1;
    overflow: hidden;
}

.TopNav.noBackground {
    background-color: white;
}

.TopNav a {
    color: #1f1f1f;
}

.TopNav a:first-child {
    position: absolute;
    top: 1rem;
    left: 1rem;
}

.TopNav .photo {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
}

.TopNav .entry {
    padding: 2rem;
    cursor: pointer;
    text-decoration: none;
}

.TopNav .entry:hover {
}

.TopNav .entry.active {
    font-weight: 600;
    background-color: white;
}
