.myBubbleUI {
	width: 100%;
	height: 600px;
}

.bubbleChild {
	width: 100%;
	height: 100%;
	border-radius: 50%;
    border: 1px solid #9AD3E9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo-image {
    width: 90px;
    height: 90px;
}

.logo-fullName {
    font-size: 0.75rem;
}
