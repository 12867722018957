.ProblemStatement {
    margin: 3rem 4rem;
}

@media (max-width:600px) {
    .ProblemStatement {
        margin: 3rem 4rem;
    }
}

.ProblemStatement h2 {
    line-height: 2.5rem;
}

.ProblemStatement.small {
    margin: 0 4rem;
}

.ProblemStatement.small h2 {
    font-size: 1.8rem;
    line-height: 3rem;
    margin: 0;
}

