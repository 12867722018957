.AboutProject {
    display: flex;
    flex-direction: row;
    z-index: 1;
    padding: 0 14rem;
    width: calc(100% - 14rem);
    height: calc(22rem + 60px);
    margin: 8rem 0;
    outline: 8rem solid white;
}

.AboutProject.odd {
    background-color: #9AD3E9;
    padding-right: 0;
}

.AboutProject:not(.odd) {
    background-color: #FFBE98;
    padding-left: 0;
}

.AboutProject.odd .leftPanel {
    width: 55%;
}

.AboutProject.odd .rightPanel {
    width: 45%;
}

.AboutProject:not(.odd) .leftPanel {
    width: 45%;
}

.AboutProject:not(.odd) .rightPanel {
    width: 55%;
}

@media (max-width:600px) {
    .AboutProject.odd .leftPanel {
        width: 50%;
    }

    .AboutProject.odd .rightPanel {
        width: 50%;
    }

    .AboutProject:not(.odd) .leftPanel {
        width: 50%;
    }

    .AboutProject:not(.odd) .rightPanel {
        width: 50%;
    }
}

.AboutProject.odd .photo img {
    outline: 30px solid #9AD3E9;
}

.AboutProject:not(.odd) .photo img {
    outline: 30px solid #FFBE98;
}

@media (max-width:600px) {
    .AboutProject {
        padding: 0 3rem;
        width: calc(100% - 3rem);
        height: calc(14rem + 20px);
        margin: 10rem 0;
        outline: 10rem solid white;
    }

    .AboutProject.odd .photo img {
        outline: 10px solid #9AD3E9;
    }

    .AboutProject:not(.odd) .photo img {
        outline: 10px solid #FFBE98;
    }
}

.AboutProject .aboutMeText {
    margin-top: 2rem;
    width: 28rem;
    color: #1f1f1f;
}

.AboutProject .title {
    font-size: 2.5rem;
    line-height: 2.5rem;
    text-decoration: none;
    color: #1f1f1f;
    width: 28rem;
}

@media (max-width:600px) {
    .AboutProject .title {
        width: 18rem;
    }

    .AboutProject .aboutMeText {
        width: 18rem;
    }
}

.AboutProject .seeMyWork {
    font-size: 2rem;
    cursor: pointer;
}

.AboutProject .leftPanel {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    align-items: end;
    background-color: white;
}

.AboutProject:not(:first-child) .leftPanel, .AboutProject:not(:first-child) .rightPanel {
    border-top: 0px solid grey;
}

.AboutProject:not(:last-child) .leftPanel, .AboutProject:not(:last-child) .rightPanel {
    border-bottom: 0px solid grey;
}

.AboutProject .rightPanel {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    background-color: white;
}

.AboutProject .photo {
    height: 22rem;
    width: 22rem;
    background-color: white;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: 0 6rem;
}

@media (max-width:600px) {
    .AboutProject .photo {
        height: 14rem;
        width: 14rem;
    }
}

.AboutProject .photo img {
    height: 100%;
    width: 100%;
}
