.Blogs {
    padding: 0 2rem;
    color: #1f1f1f;
}

.Blogs .blogsContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 8rem;
}
