.Footer {
    background: #FFBE98;
    height: 2rem;
    text-align: center;
    color: #1f1f1f;
    font-size: 1rem;
    margin-top: 3rem;
    border: 1rem solid white;
}

.Footer .heart {
    color: red;
}

.Footer .textBox {
    display: inline-block;
    padding: 0 2rem;
    background: white;
}

.Footer .email {
    text-decoration: none;
    color: #1f1f1f;
}
