.App {
    text-align: center;
    height: 100%;
    width: 100%;
}

#root {
    height: 100%;
    width: 100%;
}

html, body {
    min-height: 100%;
    width: 100%;
}

.project {
    background-color: #f5f5f5;
    margin-top: 6rem;
    color: #1f1f1f;
}

.project.odd {
    background-color: #9AD3E9;
}

.project:not(.odd) {
    background-color: #FFBE98;
}

.project-inner {
    margin: 0 14rem;
    background-color: white;
}

.project.odd .project-inner {
    margin-right: 0;
    padding-right: 14rem;
}

.project:not(.odd) .project-inner {
    margin-left: 0;
    padding-left: 14rem;
}

.alignmentWrapper {
    text-align: center;
    padding: 4rem 0;
}

@media (max-width:600px) {
    .project-inner {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .project.odd .project-inner {
        margin-right: 0;
        padding-right: 0;
    }

    .project:not(.odd) .project-inner {
        margin-left: 0;
        padding-left: 0;
    }
}

.project .project-inner iframe {
    width: 40rem;
    height: 26rem;
}

.project .project-inner .iframe-wrapper {
    text-align: center;
    margin-top: 4rem;
}

@media (max-width:600px) {
    .project .project-inner iframe {
        width: 40rem;
        height: 26rem;
    }
}

.switch-wrapper {
    position: relative;
}

.switch-wrapper > div {
    position: absolute;
}

.cursive {
    font-family: 'Caveat', cursive;
    font-weight: 300;
}

.passwordNeeded {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: lightblue;
}

.react-code-input {
    margin: 1rem 0;
}

h1, h2 {
    font-weight: normal;
}

h1 {
    font-size: 3.5rem;
}

h2 {
    font-size: 2.5rem;
}
