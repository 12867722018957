.ImageSubsection {
    margin: 4rem 4rem;
}

.ImageSubsection.small {
    margin: 0 4rem;
}

.ImageSubsection.small h2 {
    font-size: 1.8rem;
    line-height: 3rem;
    margin: 0;
}

.ImageSubsection .images {
    display: flex;
    justify-content: center;
    margin: 4rem;
    flex-direction: column;
    align-items: center;
}

.ImageSubsection .images.horizontal {
    flex-direction: row;
    gap: 4rem;
}

.ImageSubsection .image {
}

.ImageSubsection .images.horizontal .image img {
    width: 20rem !important;
}

.ImageSubsection .image img {
    width: 100%;
    height: 100%;
    margin-bottom: 1rem;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
}

.ImageSubsection.noShadow .image img {
    box-shadow: none;
}

.ImageSubsection.banner {
    border: 2rem solid #fdf1ec;
    background-color: #fdf1ec;
    border-radius: 2rem;
}

.ImageSubsection.blue.banner {
    border: 2rem solid #eff4f7;
    background-color: #eff4f7;
}

.ImageSubsection.banner .images {
    margin: 2rem;
}

.ImageSubsection .image img {
    width: 40rem;
}

.ImageSubsection:not(.banner) .image img {
    background-color: white;
}

.ImageSubsection .video-inner {
    width: 60rem;
}

.ImageSubsection .video-inner.small {
    width: 40rem;
}

.ImageSubsection .image img.wide.small {
    width: 40rem;
}

.ImageSubsection.banner .image img {
    margin-bottom: 0;
    box-shadow: none;
}

.ImageSubsection.banner .images {
    align-items: end;
}

.ImageSubsection.banner .bannerHeading {
    position: absolute;
    width: 25%;
    color: #5E3418;
}

.ImageSubsection.banner.blue .bannerHeading {
    color: #004E60;
}

@media (min-width:600px) {
    .ImageSubsection:not(.banner) .images {
        flex-wrap: wrap;
        flex-direction: row;
        background-color: #fdf1ec;
    }

    .ImageSubsection:not(.banner):not(.noBackground) .images {
        padding: 1rem;
    }

    .ImageSubsection:not(.banner).blue .images {
        background-color: #eff4f7;
    }

    .ImageSubsection:not(.banner).noBackground .images {
        background-color: white;
    }

    .ImageSubsection:not(.banner) .image {
        flex: 1 1 26%;
        margin: 1rem;
        max-width: 35rem;
    }

    .ImageSubsection:not(.banner) .image img {
        width: 100%;
        margin: 0;
    }

    .ImageSubsection:not(.banner) .description,
    .ImageSubsection:not(.banner) h2 {
        width: 75%;
        margin-left: 4rem;
    }

    .ImageSubsection:not(.banner) h2 {
        line-height: 3rem;
    }
}


@media (max-width:600px) {
    .ImageSubsection {
        margin: 4rem;
    }

    .ImageSubsection .images.horizontal {
        gap: 2rem;
    }

    .ImageSubsection .image img.long {
        width: 40rem;
    }

    .ImageSubsection .image img.long.small {
        width: 40rem;
    }

    .ImageSubsection .image img.wide {
        width: 40rem;
    }

    .ImageSubsection .image img.wide.small {
        width: 40rem;
    }

    .ImageSubsection .image img.wide.verySmall {
        width: 40rem;
    }

    .ImageSubsection .video-inner {
        width: 40rem;
    }

    .ImageSubsection .video-inner.small {
        width: 40rem;
    }

    .ImageSubsection .images.horizontal .image img {
        width: 12rem;
    }
}
