.Blog {
    width: calc(20rem + 60px);
    height: 28rem;
    margin: 2rem;
    cursor: pointer;
    text-align: center;
    background-color: white;
    text-decoration: none;
    color: #1f1f1f;
    position: relative;
    display: flex;
    flex-direction: column;
}

@media (max-width:600px) {
    .Blog {
        height: auto;
    }
}

.Blog:nth-child(even) {
    border-top: 5px solid #9AD3E9;
    flex-direction: column-reverse;
}

.Blog:nth-child(odd) {
    border-bottom: 5px solid #FFBE98;
}

@media (max-width:600px) {
    .Blog:nth-child(even) {
        flex-direction: column;
        border-bottom: 5px solid #9AD3E9;
    }
}

.Blog .image {
    width: 20rem;
    height: 15rem;
    border: 30px solid #FFBE98;
}

.Blog:nth-child(even) .image {
    border-color: #9AD3E9;
}

.Blog .image img {
    width: 100%;
    height: 100%;
}

.Blog .title {
    margin: 3rem 1rem;
}

.Blog .description {
    position: absolute;
    bottom: 1.5rem;
    left: 0;
    width: 100%;
    text-align: center;
    display: none;
}
